<template>
  <div v-if="hasResource('asset_info') || hasResource('publish_asset_info')">
    <div class="download-content">
      <div style="width:17%;left:17%">
        <div
          class="fz-18 cursor-pointer width-107 height-46 mg-b-20 mg-t-50 text-back"
          @click="back"
        >
          <i class="iconfont fz-18 black">
            &#xe647;
            <span class="back-text">返回</span>
          </i>
        </div>
        <div class="download-title">
          <span class="assets-name fz-20 bold">{{ assetName }}</span>
          <span class="mg-l-30">
            <span class="assets-name fz-18 fw-300">{{
             assetStatus!== 6 ?getAssetStatus(assetStatus): ''
            }}</span>
          </span>
        </div>
        <div
          v-for="(item, index) in assetImg"
          :key="index"
          class="asset-img mg-t-20"
        >
          <img :src="item+ '?imageMogr2/thumbnail/x512/ignore-error/1/interlace/1'" />
        </div>
      </div>
      <div style="width:80%;marginLeft:20%;borderLeft:1px solid #efefef;">
        <div>
          <asset-attribute
            :form="{...assetForm, category, tags, searchTags: search_tags, is_sss, is_grouped, is_full_screen, activeColor}"
          />
          <asset-market
            :assetForm="assetForm"
            :category="category"
            :tags="tags"
            :searchTags="search_tags"
          ></asset-market>
          <asset-download
            :assetForm="assetForm"
            :meshFormat="meshFormat"
            :albedoValue="albedoValue"
            :albedo="albedo"
            :aoValue="aoValue"
            :AO="AO"
            :materialPreset="materialPreset"
            :displacementValue="displacementValue"
            :displacement="displacement"
            :metallicValue="metallicValue"
            :metallic="metallic"
            :normal="normal"
            :normalValue="normalValue"
            :roughnessValue="roughnessValue"
            :roughness="roughness"
            :specular="specular"
            :scatter="scatter"
            :fuzz="fuzz"
            :cavity="cavity"
            :gloss="gloss"
            :cavityValue="cavityValue"
            :glossValue="glossValue"
            :checked="checked"
          />
        </div>
        <div class="mg-b-67 mg-t-50 mg-r-10 float-right">
          <div>
            <el-button
              class="default-button"
              v-if="canUpdate(assetStatus) && hasResource('asset_update')"
              @click="updateAsset"
            >
              <i class="iconfont">&#xe666;</i>重传
            </el-button>
            <!-- <el-button
              class="default-button mg-l-28"
              @click="downloadAssets"
              :loading="isLoading"
              v-if="hasResource('asset_download') && noDownload(assetStatus) && assetStatus !== 6"
            >
              <i class="iconfont">&#xe65e;</i>下载
            </el-button> -->
            <el-button
              class="default-button mg-l-28"
              v-if="canVerify(assetStatus) && hasResource('asset_verify')"
              @click="verifyAsset"
            >
              <i class="iconfont">&#xe65c;</i>核验
            </el-button>
            <el-button
              class="default-button mg-l-28"
              v-if="
                canPublish(assetStatus) && hasResource('asset_publish_review')
              "
              @click="publishAsset"
            >
              <i class="iconfont">&#xe661;</i>发布
            </el-button>
            <el-button
              class="default-button mg-l-28"
              v-if="canPublish(assetStatus)"
              @click="cancellationAsset"
            >
              作废
            </el-button>
            <el-button
              class="default-button mg-l-28"
              v-if="assetStatus == 99"
              @click="cancellationAsset"
              style="min-width:120px;"
            >
              移入已核验
            </el-button>
            <el-button
              class="default-button mg-l-28"
              v-if="
                canWithdraw(assetStatus) && hasResource('asset_cancel_review')
              "
              @click="withDrawAsset"
            >
              <i class="iconfont">&#xe65d;</i>撤回
            </el-button>
            <!-- <el-button
              class="default-button mg-l-28"
              v-if="canDelete(assetStatus) && hasResource('asset_delete')"
              @click="deleteAsset"
            >
              <i class="iconfont">&#xe678;</i>移出
            </el-button> -->
            <el-button
              class="default-button mg-l-28"
              v-if="
                canDestock(assetStatus) &&
                  (hasResource('asset_remove') ||
                    hasResource('publish_asset_remove'))
              "
              @click="handleDestock"
            >
              <i class="iconfont">&#xe660;</i> 下架
            </el-button>
            <!-- <el-button
              class="default-button mg-l-28"
              v-if="canRemake(assetStatus) && hasResource('asset_remake')"
              @click="handleRemake"
              >重制</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
    <!-- 更新弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleUpdate"
      width="458px"
      class="add-dialog dialogTip"
    >
      <div class="dialog-content">
        <div class="dialog-title">
          您将更新资产
          <span>{{ assetName }}</span>
        </div>
        <div class="dialog-dec">
          成功上传后，新的数据将覆盖旧的，系统自动发起变更审批流程。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-upload
          action="#"
          :http-request="handleSuccess"
          :show-file-list="false"
          :before-upload="onChange"
        >
          <el-button class="confirm-button" :loading="isLoading"
            >上传</el-button
          >
        </el-upload>
        <el-button @click="dialogVisibleUpdate = false" class="cancel-button"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <alert
      ref="delete"
      title="您将移出资产"
      :boldTitle="assetName"
      :confirm="
        () => {
          deleteSure();
        }
      "
    />
    <alert
      ref="cancellation"
      :title="cancellationTitle"
      :boldTitle="assetName"
      :confirm="
        () => {
            cancellationSure();
        }
      "
    />
    <alert
      ref="verify"
      title="您将核验资产"
      :boldTitle="assetName"
      :confirm="
        () => {
          assetsverify();
        }
      "
    />
    <alert
      ref="withdraw"
      title="您将撤回已发布资产"
      :boldTitle="assetName"
      :confirm="
        () => {
          withDrawSure();
        }
      "
    />
    <alert
      ref="remake"
      title="您将重置资产"
      :boldTitle="assetName"
      :confirm="
        () => {
          assetRemake();
        }
      "
    />
    <remove-dialog ref="destockPop" :updateList="go"></remove-dialog>
    <sync-image ref="syncImgPop"></sync-image>
  </div>
</template>

<script>
import RemoveDialog from "../marketAssets/mkt-components/dialogs/rm-dialog";
import api from "../../../api/assets";
import Api from "../../../api/asset";
import assetStatusMixins from "../../../mixins/asset/status";
import assetImportMixins from "../../../mixins/asset/import";
import AssetAttribute from "../../../components/asset/asset-attribute";
import AssetMarket from "../../../components/asset/asset-market";
import AssetDownload from "../../../components/asset/asset-download";
import Alert from "../../../components/alert/index";
import assetDelete from "../../../mixins/asset/delete";
import syncImage from "../../asset-management/marketAssets/mkt-components/dialogs/sync-img";
export default {
  components: {
    RemoveDialog,
    AssetAttribute,
    AssetDownload,
    Alert,
    syncImage,
    AssetMarket,
  },
  mixins: [assetStatusMixins, assetImportMixins, assetDelete],
  data() {
    return {
      isLoading: false,
      showClose: false,
      // 资产名称
      assetName: "",
      assetStatus: "",
      reviewStatus: "",
      assetImg: [],
      assetsOriginId: "",
      labelPosition: "left",
      checked: true,
      // 更新弹框
      dialogVisibleUpdate: false,
      // 数据
      assetForm: {},
      originId: "",
      tags: "",
      search_tags: "",
      albedoValue: "",
      albedo: [],
      aoValue: "",
      AO: [],
      displacementValue: "",
      displacement: [],
      metallicValue: "",
      metallic: [],
      normalValue: "",
      normal: [],
      roughnessValue: "",
      roughness: [],
      specular: "",
      scatter: "",
      fuzz: "",
      meshFormat: "",
      category: "",
      materialPreset: "",
      cavity: [],
      gloss: [],
      glossValue: "",
      cavityValue: "",
      activeColor: "",
      is_sss: "",
      is_grouped: "",
      is_full_screen: "",
      cancellationTitle: ''
    };
  },
  methods: {
    init() {
      let data = this.$route.query.id;
      // if (this.hasResource("publish_asset_info")) {
      api.assetsInfo(data).then((res) => {
        if (res.data.code === 10200) {
          const resData = res.data.data || {};
          this.originId = res.data.data.origin_id;
          this.assetName = res.data.data.name;
          this.assetStatus = res.data.data.status;
          this.assetImgs = res.data.data.previews;
          this.assetForm = res.data.data;
          this.assetForm.modelState = resData.model_state === 1 ? '不封闭' : resData.model_state === 0 ? '封闭' : '';
          this.assetForm.chineseTags = resData.chinese_tags && resData.chinese_tags.length ? resData.chinese_tags.join(',') : '';
          this.tags = res.data.data.tags ? res.data.data.tags.toString() : "";
          this.activeColor = res.data.data.color || [];
          this.search_tags = res.data.data.search_tags
            ? res.data.data.search_tags.toString()
            : "";
          this.materialPreset = res.data.data.material_preset
            ? res.data.data.material_preset.toString()
            : "";
          this.albedo = res.data.data.albedo;
          this.AO = res.data.data.ao;
          this.displacement = res.data.data.displacement;
          this.metallic = res.data.data.metallic;
          this.normal = res.data.data.normal;
          this.roughness = res.data.data.roughness;
          this.cavity = res.data.data.cavity;
          this.gloss = res.data.data.gloss;
          this.specular = res.data.data.specular
            ? res.data.data.specular.toString()
            : "";
          this.scatter = res.data.data.scatter
            ? res.data.data.scatter.toString()
            : "";
          this.fuzz = res.data.data.fuzz ? res.data.data.fuzz.toString() : "";
          this.meshFormat = res.data.data.mesh_format
            ? res.data.data.mesh_format.toString()
            : "";
          this.is_sss = !res.data.data.is_sss ? "不需要" : "需要";
          this.is_grouped = !res.data.data.is_grouped? "否" : "是";
          this.is_full_screen =
            !res.data.data.is_full_screen? "不支持" : "支持";
          const categroyInfo = res.data.data.categories;
          const categroyData = [];
          categroyInfo.forEach((item, index) => {
            categroyData.push(item.label);
          });
          this.category = categroyData.toString();
        } else {
          this.$$error(res.data.message);
        }
        this.assetImgs.forEach((item) => {
          this.assetImg.push(item.url);
        });
      });
      // }
    },

    handleDestock() {
      let obj = { id: this.$route.query.id, name: this.assetName };
      this.$nextTick(() => {
        this.$refs["destockPop"].show(obj);
      });
    },
    go() {
      this.$router.push("/assets/all");
    },
    back() {
      this.$router.push("/assets/all");
      this.msgBus.$emit("assetList");
    },
    // 下载
    downloadAssets() {
      this.isLoading = true;
      let data = {
        id: this.$route.query.id,
        compressed: 1,
        market: "US",
      };
      api.download(data).then((res) => {
        if (res.data.code === 10200) {
          this.downloadFile(res.data.data.data.url[0]);
          this.$nextTick(() => {
            this.$$success("已下载");
          });
        } else {
          this.$$error(res.data.message);
        }
        this.isLoading = false;
      });
    },
    // 更新弹框
    updateAsset() {
      this.dialogVisibleUpdate = true;
    },
    // 核验弹框
    verifyAsset() {
      this.$nextTick(() => {
        this.$refs.verify.show = true;
      });
    },
    assetsverify() {
      let data = {
        market: "US",
        id: this.$route.query.id,
      };
      api.verify(data).then((res) => {
        if (res.data.code === 10200) {
          this.$router.push("/assets/all");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    // 撤回弹框
    withDrawAsset() {
      this.$nextTick(() => {
        this.$refs.withdraw.show = true;
      });
    },
    withDrawSure() {
      let data = {
        market: "US",
        id: this.$route.query.id,
        comment: "",
      };
      api.withDraw(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success(res.data.message);
          this.$router.push("/assets/all");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    // 作废弹框
    cancellationAsset() {
        this.$nextTick(() => {
        this.$refs.cancellation.show = true;
        this.cancellationTitle = this.assetStatus === 2 ?'您将作废资产':'资产移入已核验';
      });
    },
    cancellationSure() {
      this.delLoading = true;
      let data = {
        id: this.$route.query.id,
        status: this.assetStatus ===2 ? 99 : 2
      };
      api.discardDelete(data).then((res) => {
        if (res.data.code === 10200) {
        this.init();
          this.$$success(res.data.message);
        }else {
            this.$$error(res.data.message);
        }
      });
    },
    // 发布
    publishAsset() {
      this.$router.push({
        path: "/asset/publish",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    handleRemake() {
      this.$nextTick(() => {
        this.$refs.remake.show = true;
      });
    },
    assetRemake() {
      let data = {
        market: "US",
        id: this.$route.query.id,
      };
      api.remake(data).then((res) => {
        if (res.data.code === 10200) {
          this.$router.push("/assets/all");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    // 更新
    handleImgSync() {
      this.$nextTick(() => {
        this.$refs["syncImgPop"].show(this.$route.query);
      });
    },
    // 变更
    handleChange() {
      this.$router.push({
        path: "/asset-change",
        query: {
          id: this.$route.query.id,
        },
      });
    },
  },
  mounted() {
    this.init();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less">
@import "./scss/download.less";
@import "./scss/index.less";
</style>
